<template>
  <div>
    <video
      ref="videoPlayer"
      class="video-js vjs-big-play-centered"
    ></video>
    <div>
      <!-- <v-icon v-if="!isPlaying" color="white" @click="playTheVideo">mdi-play</v-icon>
    <v-icon v-else color="white" @click="pauseTheVideo">mdi-pause</v-icon>
    <v-icon>mdi-fullscreen</v-icon>
    <v-icon>mdi-fullscreen-exit</v-icon> -->
    </div>
  </div>
</template>
<script>
import "video.js/dist/video-js.css";
import videojs from "video.js";

export default {
  name: "VideoPlayer",
  props: {
    options: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      player: null,
      completeCheck: true,
      isPlaying: false,
    };
  },
  computed: {
    
  },
  watch: {
  },
  methods: {
    // playTheVideo(){
    //   this.player.play()
    // },
    // pauseTheVideo(){
    //   this.player.pause()
    // },
    // playing(){
    //   this.isPlaying = true
    // },
    // paused(){
    //   this.isPlaying=false
    // },
    watchplayer() {
      let time = Math.round(
        (this.player.currentTime() / this.player.duration()) * 100
      );
      // console.log(time,"jdjdjd")

      if (this.$route.matched[0].path.substring(1) === "parent") {
        if (time == 80 && this.completeCheck) {
          console.log(time, "80% has done");
          this.$root.$refs.videoSection.submitCompleted(
            this.$store.state.videoSection.videoModal.videoObject,
            this.player.currentTime()
          );
          this.completeCheck = false;
        }
      }
    },
  },
  mounted() {
    this.player = videojs(
      this.$refs.videoPlayer,
      this.options,
      function onPlayerReady() {
        // console.log("onPlayerReady", this);
      }
    );
    this.player.controlBar.progressControl.disable();
    const self = this;
    this.player.on("timeupdate",function(){
      console.log("timeupdate",self.player.currentTime(),self.player.duration())
      // alert("time")
      self.watchplayer()
      // self.player.currentTime()
    });
      this.player.on("ended", () => {
      this.$emit("video-ended");
    });
  },
  beforeDestroy() {
    this.player.dispose();
  },

};
</script>
<style scoped>
.video {
  width: 65%;
}
</style>
